import Spinner from './Spinner';
import Select from './Select';
import useSelect from '../hooks/useSelect';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProviders,
  reset,
  resetProviders,
  resetSearchHasBeenMadeForThisType,
} from '../features/providers/providersSlice';
import { linkLikehoverHandlingClasses } from '../utilities/classes';

const FinderByDistance = ({ changeTab }) => {
  const minimumCharactersToSearch = 3;
  const [showMinimumCharacters, setShowMinimumCharacters] = useState(false);

  const [disableButton, setDisableButton] = useState(false);
  const loading = useSelector((state) => state.providers.loading);

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetState = () => {
    setSearchParams({});
    dispatch(reset());
    dispatch(resetProviders());
  };
  const changeTabDecorated = () => {
    dispatch(resetSearchHasBeenMadeForThisType());
    resetState();
    changeTab();
  };

  const lookup = (e) => {
    e.preventDefault();
    if (disableButton) {
      return;
    }
    setSearchParams({ which: 'distance', distance, specialty, location });
  };

  useEffect(() => {
    const keyValueArray = {};

    // Iterate over the parameters and extract keys and values
    searchParams.forEach((value, key) => {
      keyValueArray[key] = value;
    });
    if (Object.keys(keyValueArray).length > 0) {
      try {
        dispatch(resetProviders());
        dispatch(fetchProviders(keyValueArray));
      } catch (e) {
        // console.error(e);
      }
    }
    maybeDisableButton();
  }, [searchParams]);

  const [location, setLocation] = useState(searchParams.get('location') ?? '');

  const setLocationWrap = (e) => {
    if (e.key === 'Enter') {
      lookup();
    }
    setLocation(e.target.value);
  };

  const {
    set: setDistance,
    selected: distance,
    options: distanceOptions,
  } = useSelect({
    options: [
      { value: 25, label: '25 miles' },
      { value: 50, label: '50 miles' },
      { value: 100, label: '100 miles' },
      { value: 150, label: '150 miles' },
    ],
    defaultValue: searchParams.get('distance') ?? 50,
  });

  const {
    set: setSpecialty,
    selected: specialty,
    options: specialtyOptions,
  } = useSelect({
    options: window?.sw?.specialties,
    defaultValue: searchParams.get('specialty') ?? '',
    blankLabel: ' All Specialties',
  });

  const maybeDisableButton = () => {
    let disabled = !(
      distance &&
      location &&
      !loading &&
      location.length >= minimumCharactersToSearch
    );
    if (
      searchParams.get('specialty') === specialty &&
      searchParams.get('location') === location &&
      searchParams.get('distance') === distance
    ) {
      disabled = true;
    }

    setDisableButton(disabled);
  };

  useEffect(() => {
    const providerLengthMet =
      !!location && location.length >= minimumCharactersToSearch;

    setShowMinimumCharacters(location && !providerLengthMet);
  }, [minimumCharactersToSearch, location, loading, searchParams]);

  useEffect(() => {
    maybeDisableButton();
  }, [distance, specialty, location, loading]);

  return (
    <form id="finder-form" onSubmit={lookup} className="w-full lg:w-[425px]">
      <div className="flex justify-between items-center w-full gap-4">
        <div className="w-full">
          <p>
            <small>Enter Your Zip Code</small>
          </p>
          <input
            style={{
              padding: '10px',
              border: showMinimumCharacters ? '1px solid red' : '',
            }}
            className="border border-dark border-2 rounded-[10px] md-text px-[8px] y-[7px] lg:mr-5 w-full"
            type="text"
            value={location}
            onChange={(e) => setLocationWrap(e)}
            placeholder="e.g., 90210"
          />
        </div>

        <div>
          <p>
            <small>Enter Distance</small>
          </p>

          <Select
            className="lg:block hidden  w-[200px]"
            value={distance}
            setter={setDistance}
            options={distanceOptions}
          />
          <Select
            className="lg:hidden"
            value={distance}
            setter={setDistance}
            options={distanceOptions.map((m) => {
              return {
                label: m.label.replace('miles', 'mi'),
                value: m.value,
              };
            })}
          />
        </div>
      </div>
      <p style={{ color: 'red' }} className="text-left mb-2">
        {showMinimumCharacters ? (
          <>
            Please enter at least {minimumCharactersToSearch} characters to
            search
          </>
        ) : (
          <>&nbsp;</>
        )}
      </p>
      {specialtyOptions &&
        Array.isArray(specialtyOptions) &&
        specialtyOptions.length > 2 && ( //if we have all specialties + 2 specialties, show it
          <div className="">
            <div>
              <p>
                <small>Specialty</small>
              </p>
              <Select
                className="lg:block hidden !w-full text-center"
                value={specialty}
                setter={setSpecialty}
                options={specialtyOptions}
              />
              <Select
                className="lg:hidden !w-full"
                value={specialty}
                setter={setSpecialty}
                options={specialtyOptions}
              />
            </div>
          </div>
        )}
      <p className="text-center w-full mt-5 font-bold">
        Currently Searching by Location
      </p>
      <p className="text-center w-full">
        <span
          role="button"
          style={loading ? { pointerEvents: 'none', opacity: '80%' } : {}}
          onClick={changeTabDecorated}
          className={linkLikehoverHandlingClasses}
        >
          Click Here to Search by Name →
        </span>
      </p>

      <button
        role="submit"
        className={`w-auto cursor-pointer o-button green mt-5 block text-center mx-auto`}
        disabled={disableButton}
        style={
          disableButton
            ? {
                pointerEvents: 'none',
                opacity: '60%',
              }
            : {}
        }
      >
        <span>
          Search
          {loading && <Spinner className="inline-block ml-5" />}
        </span>
      </button>
    </form>
  );
};

export default FinderByDistance;

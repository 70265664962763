import "./Select.css";
import htmlDecode from "../utilities/htmlDecode";
const Select = ({ options, setter, value, className = "" }) => {
  const set = (e) => {
    setter(e.target.value);
  };

  return (
    <div className={`${className} select-wrapper`}>
      <select className="select" onChange={set} value={value}>
        {options.map(({ value, label }) => {
          return (
            <option key={value} value={value}>
              {htmlDecode(label)}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default Select;

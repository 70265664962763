const SWIcon = ({ className, icon, alt, height, width }) => {
  let style = null;
  if (height && width) {
    style = { height, width };
  }

  return (
    icon && (
      <div>
        <img className={className} src={icon} alt={alt} style={style} />
      </div>
    )
  );
};

SWIcon.defaultProps = {
  height: null,
  width: null,
  className: '',
};

export default SWIcon;

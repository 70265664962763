export default function scrollToMap(atts = {}) {
  const defaults = { mobileOnly: false, offsetTop: 0 };
  const { mobileOnly, offsetTop } = { ...defaults, ...atts };
  if (mobileOnly && window.innerWidth > 1024) {
    return;
  }
  let $wrapper =
    document.getElementById('g-map-wrapper') ||
    document.getElementById('sw-map-container');

  if (!$wrapper) return;
  const elementPosition = $wrapper.offsetTop + offsetTop;
  window.scrollTo({ top: elementPosition, behavior: 'smooth' });
}

import Spinner from './Spinner';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProviders,
  reset,
  resetProviders,
  resetSearchHasBeenMadeForThisType,
} from '../features/providers/providersSlice';
import { linkLikehoverHandlingClasses } from '../utilities/classes';

const FinderByName = ({ changeTab }) => {
  const minimumCharactersToSearch = 3;

  const loading = useSelector((state) => state.providers.loading);
  const [disableButton, setDisableButton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [providerName, setProviderName] = useState(
    searchParams.get('providerName'),
  );
  const [showMinimumCharacters, setShowMinimumCharacters] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      disableButton ||
      !providerName ||
      providerName.length < minimumCharactersToSearch
    ) {
      return;
    }
    const keyValueArray = {};

    // Iterate over the parameters and extract keys and values
    searchParams.forEach((value, key) => {
      keyValueArray[key] = value;
    });
    if (Object.keys(keyValueArray).length > 0) {
      try {
        dispatch(fetchProviders(keyValueArray));
      } catch (e) {
        // console.error(e);
      }
    }
  }, [searchParams]);

  const resetState = () => {
    dispatch(resetProviders());
    setSearchParams({});
    dispatch(reset());
  };
  const changeTabDecorated = () => {
    dispatch(resetSearchHasBeenMadeForThisType());
    resetState();
    changeTab();
  };

  useEffect(() => {
    const providerLengthMet =
      !!providerName && providerName.length >= minimumCharactersToSearch;

    setShowMinimumCharacters(providerName && !providerLengthMet);

    setDisableButton(
      !(
        providerName &&
        providerLengthMet &&
        !loading &&
        searchParams.get('providerName') !== providerName
      ),
    );
  }, [minimumCharactersToSearch, providerName, loading, searchParams]);

  useEffect(() => {
    if (
      !disableButton &&
      providerName &&
      providerName.length > minimumCharactersToSearch
    ) {
      lookup();
    }
  }, []);
  const lookup = (e) => {
    e && e.preventDefault();
    if (disableButton) {
      return;
    }
    setSearchParams({ which: 'name', providerName });
  };

  const setProviderNameWrap = (e) => {
    if (e.key === 'Enter') {
      lookup();
    }
    setProviderName(e.target.value);
  };

  return (
    <form id="finder-form" onSubmit={lookup} className="w-full lg:w-[425px]">
      <div className="flex flex-col">
        <p>
          <small>Enter provider name</small>
        </p>
        <input
          style={{ padding: '10px' }}
          className="border border-dark border-2 rounded-[10px] md-text px-[8px] y-[7px] lg:mr-5"
          type="text"
          value={providerName ?? ''}
          onChange={(e) => setProviderNameWrap(e)}
          placeholder="e.g., Robertson"
        />
        <p style={{ color: 'red' }} className="text-center">
          {showMinimumCharacters ? (
            <>
              Please enter at least {minimumCharactersToSearch} characters to
              search
            </>
          ) : (
            <>&nbsp;</>
          )}
        </p>
      </div>
      <p className="text-center w-full mt-5 font-bold">
        Currently Searching by Name
      </p>
      <p className={`text-center w-full`}>
        <span
          role="button"
          style={loading ? { pointerEvents: 'none', opacity: '80%' } : {}}
          className={linkLikehoverHandlingClasses}
          onClick={changeTabDecorated}
        >
          Click Here to Search by Location →
        </span>
      </p>

      <button
        role="submit"
        className={`w-auto cursor-pointer o-button green mt-5 block text-center mx-auto`}
        disabled={disableButton}
        style={
          disableButton
            ? {
                pointerEvents: 'none',
                opacity: '60%',
              }
            : {}
        }
      >
        <span>
          Search
          {loading && <Spinner className="inline-block ml-5" />}
        </span>
      </button>
    </form>
  );
};

export default FinderByName;

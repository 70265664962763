import { useSelector, useDispatch } from "react-redux";
import { close as closeModal } from "../features/providers/emailModalSlice";

const EmailModal = () => {
  const open = useSelector((state) => state.emailModal.open);
  const providers = useSelector((state) => state.providers);
  const dispatch = useDispatch();

  return (
    <div
      aria-describedby="what-are-these-modal-description"
      onClick={() => dispatch(closeModal())}
      className={`${
        open ? "flex" : "hidden"
      } lg:bg-white/80 fixed px-5 justify-center items-center top-0 left-0 h-full w-full bg-white z-[100000] `}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        aria-hidden={!open ? "true" : "false"}
        aria-labelledby="ossio-fiber-levels-modal-title"
        aria-describedby="what-are-these-modal-description"
        role="dialog"
        className="relative h-[75vh] lg:h-[500px] py-5 px-5 mx-5 border-ossio-green border-2 rounded-xl lg:min-w-[600px] lg:w-[50vw] bg-white lg:py-[32px] lg:px-[64px] max-h-[75vh] overflow-y-scroll"
      >
        <div className="sr-only" id="what-are-these-modal-description">
          This is a dialog to provide you the ability to copy and email yourself
          results of your OSSIOfiber Surgeon Locator Search
        </div>
        <button
          title="Close Dialog"
          className=" hover:opacity-[80%] top-[24px] right-[24px] absolute cursor-pointer"
          onClick={() => dispatch(closeModal())}
        >
          <img
            style={{ height: "24px", width: "24px" }}
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMS45OTUgNTExLjk5NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTQzNy4xMjYgNzQuOTM5Yy05OS44MjYtOTkuODI2LTI2Mi4zMDctOTkuODI2LTM2Mi4xMzMgMEMyNi42MzcgMTIzLjMxNCAwIDE4Ny42MTcgMCAyNTYuMDA1czI2LjYzNyAxMzIuNjkxIDc0Ljk5MyAxODEuMDQ3YzQ5LjkyMyA0OS45MjMgMTE1LjQ5NSA3NC44NzQgMTgxLjA2NiA3NC44NzRzMTMxLjE0NC0yNC45NTEgMTgxLjA2Ni03NC44NzRjOTkuODI2LTk5LjgyNiA5OS44MjYtMjYyLjI2OC4wMDEtMzYyLjExM3pNNDA5LjA4IDQwOS4wMDZjLTg0LjM3NSA4NC4zNzUtMjIxLjY2NyA4NC4zNzUtMzA2LjA0MiAwLTQwLjg1OC00MC44NTgtNjMuMzctOTUuMjA0LTYzLjM3LTE1My4wMDFzMjIuNTEyLTExMi4xNDMgNjMuMzctMTUzLjAyMWM4NC4zNzUtODQuMzc1IDIyMS42NjctODQuMzU1IDMwNi4wNDIgMCA4NC4zNTUgODQuMzc1IDg0LjM1NSAyMjEuNjY3IDAgMzA2LjAyMnoiIGZpbGw9IiMwMDAwMDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiPjwvcGF0aD48cGF0aCBkPSJtMzQxLjUyNSAzMTAuODI3LTU2LjE1MS01Ni4wNzEgNTYuMTUxLTU2LjA3MWM3LjczNS03LjczNSA3LjczNS0yMC4yOS4wMi0yOC4wNDYtNy43NTUtNy43NzUtMjAuMzEtNy43NTUtMjguMDY1LS4wMmwtNTYuMTkgNTYuMTExLTU2LjE5LTU2LjExMWMtNy43NTUtNy43MzUtMjAuMzEtNy43NTUtMjguMDY1LjAyLTcuNzM1IDcuNzU1LTcuNzM1IDIwLjMxLjAyIDI4LjA0Nmw1Ni4xNTEgNTYuMDcxLTU2LjE1MSA1Ni4wNzFjLTcuNzU1IDcuNzM1LTcuNzU1IDIwLjI5LS4wMiAyOC4wNDYgMy44NjggMy44ODcgOC45NjUgNS44MTEgMTQuMDQzIDUuODExczEwLjE1NS0xLjk0NCAxNC4wMjMtNS43OTJsNTYuMTktNTYuMTExIDU2LjE5IDU2LjExMWMzLjg2OCAzLjg2OCA4Ljk0NSA1Ljc5MiAxNC4wMjMgNS43OTJhMTkuODI4IDE5LjgyOCAwIDAgMCAxNC4wNDMtNS44MTFjNy43MzMtNy43NTYgNy43MzMtMjAuMzExLS4wMjItMjguMDQ2eiIgZmlsbD0iIzAwMDAwMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4="
          />
        </button>
        <p
          id="ossio-fiber-levels-modal-title"
          className="mb-[32px] font-bold lg-text"
        >
          Email Your Results
        </p>
        <div style={{ padding: "20px", backgroundColor: "#eee" }}>
          <pre>{JSON.stringify(providers, 0, 1)}</pre>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;

import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Spinner from './Spinner';
import {
  fetchProviders,
  resetSearchHasBeenMadeForThisType,
  setCenterLatLng,
} from '../features/providers/providersSlice';
import { useDispatch, useSelector } from 'react-redux';
import useUserSession from '../hooks/useSessionData';
import SingleResult from './SingleResult';
import Map from './Map';

import SWIcon from './SWIcon';
import QuestionMarkIcon from '../assets/interrogation-mark.svg';
import EmailModal from './EmailModal';
import generateMailto from '../utilities/generateMailto';
import FinderByDistance from './FinderByDistance';
import FinderByName from './FinderByName';
import { useSearchParams } from 'react-router-dom';
import scrollToMap from '../utilities/scrollToMap';
import { linkLikehoverHandlingClasses } from '../utilities/classes';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const Finder = () => {
  const [showMap, setShowMap] = useState(false);
  const [searchParams] = useSearchParams();
  const [whichTab, setWhichTab] = useState(
    searchParams.get('providerName') ? 'name' : 'distance',
  );
  const sessionData = useUserSession();

  const setTabToName = () => {
    setWhichTab('name');
  };
  const setTabToDistance = () => {
    setWhichTab('distance');
  };
  const maxProviders = 30;
  const scrollRef = useRef(null);
  const doctorListRef = useRef(null);
  const dispatch = useDispatch();

  const [expandedWhatAreThese, setExpandedWhatAreThese] = useState(false);
  const [hamburgerHidden, setHamburgerHidden] = useState(true);
  const centerLatLng = useSelector((state) => state.providers.centerLatLng);
  const memoizedCenterLatLng = useMemo(
    () => centerLatLng,
    [centerLatLng.lat, centerLatLng.lng],
  );

  const searchHasBeenMadeForThisType = useSelector(
    (state) => state.providers.searchHasBeenMadeForThisType,
  );

  const providers = useSelector(
    (state) => state.providers.data.providersFiltered,
  );
  const info = useSelector((state) => {
    return state.providers.data.info;
  });
  const loading = useSelector((state) => state.providers.loading);
  const error = useSelector((state) => state.providers.error);
  const firstSearchOccurred = useSelector(
    (state) => state.providers.firstSearchOccurred,
  );

  const [loadingDoctorList, setLoadingDoctorList] = useState(false);
  const [markers, setMarkers] = useState([]);

  const toggleMaps = () => {
    setLoadingDoctorList(true);
    setTimeout(function () {
      setHamburgerHidden((h) => !h);
      setLoadingDoctorList(false);
    }, 500);
  };

  const setMarkersMemoized = useCallback((uniqueProviders) => {
    setMarkers(
      uniqueProviders.map((provider) => {
        const { lat, lng } = provider.location;
        return { lat, lng, provider };
      }),
    );
  }, []);

  useEffect(() => {
    if (!Array.isArray(providers) || providers.length === 0) {
      return;
    }
    let center;
    if ('name' === searchParams.get('which')) {
      center = { lat: 39.8283, lng: 98.5795 };
    }
    if ('distance' === searchParams.get('which')) {
      center = providers[0].userLatLng;
    }
    if (firstSearchOccurred) {
      scrollToMap({ mobileOnly: true, offsetTop: -100 });
    }

    if (!center) {
      return;
    }
    dispatch(setCenterLatLng(center));
    const uniqueProviders = Array.from(
      new Set(providers.map((elem) => JSON.stringify(elem))),
    ).map((str) => JSON.parse(str));

    setMarkersMemoized(uniqueProviders);
  }, [providers]); // This might be recalculating too often

  return (
    <div
      ref={scrollRef}
      className="container bg-white"
      style={{
        paddingBottom: '64px',
        paddingTop: '64px',
      }}
    >
      <EmailModal />

      <div className="section lg:flex justify-center  mx-auto relative bg-white">
        <div className=" lg:basis-[55%] overflow-auto  flex-wrap basis-full justify-start lg:flex flex-col items-center">
          {error && (
            <div className="shake border-ossio-green border-2 relative p-5 rounded-xl font-bold text-black">
              <div className="absolute bg-ossio-green opacity-[30%] h-full w-full top-0 left-0"></div>
              {error}
            </div>
          )}
          <h2 className="my-5 text-ossio-green lg-text lg:xl-text">
            Find An OSSIO<i>fiber</i>
            <sup>®</sup> Surgeon
          </h2>
          {whichTab === 'distance' && (
            <FinderByDistance
              changeTab={setTabToName}
              setShowMap={setShowMap}
            />
          )}

          {whichTab === 'name' && (
            <FinderByName
              changeTab={setTabToDistance}
              setShowMap={setShowMap}
            />
          )}

          {window?.sw?.ossioLevels && (
            <>
              <div className="w-full flex mt-5 items-center justify-between max-w-[400px]">
                <div className="font-bold flex relative whitespace-nowrap">
                  OSSIO<i>fiber</i>® Levels
                  <span className="lg:hidden">
                    <img
                      onClick={() =>
                        setExpandedWhatAreThese(!expandedWhatAreThese)
                      }
                      src={QuestionMarkIcon}
                      className="h-[16px] left-[6px] w-auto hover:opacity-[80%] cursor-pointer"
                      alt="What Are These?"
                    />
                  </span>
                </div>
                {window.sw.ossioLevels
                  .filter((i) => !!i.type_icon)
                  .map(({ name, type_icon }) => {
                    return (
                      <div key={name} className="flex justify-between">
                        <div className="">
                          <SWIcon
                            alt={name}
                            icon={type_icon}
                            height={24}
                            className={'max-h-[50px] !w-auto'}
                          />
                        </div>
                      </div>
                    );
                  })}

                <div
                  onClick={() => setExpandedWhatAreThese(!expandedWhatAreThese)}
                  className={linkLikehoverHandlingClasses}
                >
                  <span className="">What Are These?</span>
                </div>
              </div>
              <div>
                {
                  <div
                    aria-describedby="what-are-these-modal-description"
                    onClick={() =>
                      setExpandedWhatAreThese(!expandedWhatAreThese)
                    }
                    className={`${
                      expandedWhatAreThese ? 'flex' : 'hidden'
                    } lg:bg-white/80 fixed px-5 justify-center items-center top-0 left-0 h-full w-full bg-white z-[10000] `}
                  >
                    <div
                      onClick={(e) => e.stopPropagation()}
                      aria-hidden={!expandedWhatAreThese ? 'true' : 'false'}
                      aria-labelledby="ossio-fiber-levels-modal-title"
                      aria-describedby="what-are-these-modal-description"
                      role="dialog"
                      className="relative h-[75vh] lg:h-[500px] py-5 px-5 mx-5 border-ossio-green border-2 rounded-xl lg:min-w-[600px] lg:w-[50vw] bg-white lg:py-[32px] lg:px-[64px] max-h-[75vh] overflow-y-scroll"
                    >
                      <div
                        className="sr-only"
                        id="what-are-these-modal-description"
                      >
                        This is a dialog window which overlays the main content
                        of the page. The modal describe different
                        OSSIOfiber&reg; levels for providers.
                      </div>
                      <button
                        title="Close Dialog"
                        className=" hover:opacity-[80%] top-[24px] right-[24px] absolute cursor-pointer"
                        onClick={() =>
                          setExpandedWhatAreThese(!expandedWhatAreThese)
                        }
                      >
                        <img
                          style={{ height: '24px', width: '24px' }}
                          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDUxMS45OTUgNTExLjk5NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTQzNy4xMjYgNzQuOTM5Yy05OS44MjYtOTkuODI2LTI2Mi4zMDctOTkuODI2LTM2Mi4xMzMgMEMyNi42MzcgMTIzLjMxNCAwIDE4Ny42MTcgMCAyNTYuMDA1czI2LjYzNyAxMzIuNjkxIDc0Ljk5MyAxODEuMDQ3YzQ5LjkyMyA0OS45MjMgMTE1LjQ5NSA3NC44NzQgMTgxLjA2NiA3NC44NzRzMTMxLjE0NC0yNC45NTEgMTgxLjA2Ni03NC44NzRjOTkuODI2LTk5LjgyNiA5OS44MjYtMjYyLjI2OC4wMDEtMzYyLjExM3pNNDA5LjA4IDQwOS4wMDZjLTg0LjM3NSA4NC4zNzUtMjIxLjY2NyA4NC4zNzUtMzA2LjA0MiAwLTQwLjg1OC00MC44NTgtNjMuMzctOTUuMjA0LTYzLjM3LTE1My4wMDFzMjIuNTEyLTExMi4xNDMgNjMuMzctMTUzLjAyMWM4NC4zNzUtODQuMzc1IDIyMS42NjctODQuMzU1IDMwNi4wNDIgMCA4NC4zNTUgODQuMzc1IDg0LjM1NSAyMjEuNjY3IDAgMzA2LjAyMnoiIGZpbGw9IiMwMDAwMDAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiPjwvcGF0aD48cGF0aCBkPSJtMzQxLjUyNSAzMTAuODI3LTU2LjE1MS01Ni4wNzEgNTYuMTUxLTU2LjA3MWM3LjczNS03LjczNSA3LjczNS0yMC4yOS4wMi0yOC4wNDYtNy43NTUtNy43NzUtMjAuMzEtNy43NTUtMjguMDY1LS4wMmwtNTYuMTkgNTYuMTExLTU2LjE5LTU2LjExMWMtNy43NTUtNy43MzUtMjAuMzEtNy43NTUtMjguMDY1LjAyLTcuNzM1IDcuNzU1LTcuNzM1IDIwLjMxLjAyIDI4LjA0Nmw1Ni4xNTEgNTYuMDcxLTU2LjE1MSA1Ni4wNzFjLTcuNzU1IDcuNzM1LTcuNzU1IDIwLjI5LS4wMiAyOC4wNDYgMy44NjggMy44ODcgOC45NjUgNS44MTEgMTQuMDQzIDUuODExczEwLjE1NS0xLjk0NCAxNC4wMjMtNS43OTJsNTYuMTktNTYuMTExIDU2LjE5IDU2LjExMWMzLjg2OCAzLjg2OCA4Ljk0NSA1Ljc5MiAxNC4wMjMgNS43OTJhMTkuODI4IDE5LjgyOCAwIDAgMCAxNC4wNDMtNS44MTFjNy43MzMtNy43NTYgNy43MzMtMjAuMzExLS4wMjItMjguMDQ2eiIgZmlsbD0iIzAwMDAwMCIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4="
                        />
                      </button>
                      <p
                        id="ossio-fiber-levels-modal-title"
                        className="mb-[32px] font-bold lg-text"
                      >
                        OSSIO<i>fiber</i>&reg; Levels
                      </p>
                      {window.sw.ossioLevels.map(
                        ({ name, description, type_icon }) => {
                          return (
                            <div className="mb-5" key={name}>
                              <div className="flex">
                                {type_icon && (
                                  <img
                                    className="object-contain inline-block mr-5 max-h-[50px] !w-auto "
                                    src={type_icon}
                                    alt={name}
                                  />
                                )}
                                {!type_icon && (
                                  <span className="inline-flex w-[51px] min-w-[51px] h-auto mr-5">
                                    &nbsp;
                                  </span>
                                )}
                                <div className="inline-block">
                                  <strong>{name}</strong> {description}
                                </div>
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  </div>
                }
              </div>
            </>
          )}
          {firstSearchOccurred &&
            Array.isArray(providers) &&
            providers.length === 0 && (
              <>
                <div
                  id="swpf-info"
                  className="mb-[0px] mt-[32px] border-t-2 border-b-2 border-ossio-green py-4 w-full text-center"
                >
                  {info}
                </div>
                <div className="flex flex-col items-center lg:py-[35px] lg:px-[85px] py-[24px] px-0">
                  <p className="md-text mb-5">
                    <strong>No Results Found</strong>
                  </p>
                  <p className="md-text text-black text-center">
                    If you don’t see a Surgeon in your area, please Contact Us
                    so we can assist.
                  </p>
                  <a
                    href="/contact"
                    className={`inline-block cursor-pointer o-button green mt-5 block text-center`}
                    style={{ maxWidth: '200px' }}
                  >
                    Contact Us
                  </a>
                </div>
              </>
            )}
          {Array.isArray(providers) && providers.length > 0 && (
            <>
              <div className="flex justify-center">
                <span
                  target="_blank"
                  rel="noopener noreferrer"
                  role="button"
                  className={`mx-auto w-auto inline-block mt-[48px] cursor-pointer o-button green mt-5 block text-center ${
                    !location ? 'pointer-events-none' : ''
                  }`}
                  style={{
                    pointerEvents: `${loading || !location ? 'none' : 'auto'}`,
                    opacity: `${loading || !location ? '0.5' : 1}`,
                  }}
                  // onClick={setEmailModalVisible}
                  onClick={() =>
                    generateMailto(providers, 'tmp1', 'tmp2', maxProviders)
                  }
                >
                  <span>Email Me These Results</span>
                </span>
              </div>
              <p
                className={`mt-4 italic mx-auto w-3/4 ${
                  providers.length >= maxProviders
                    ? 'text-[13px]'
                    : 'text-[14px]'
                }  text-center`}
              >
                <>
                  This will open a new compose email in your default email
                  provider. You can then set your email address and send the
                  email directly from there.
                </>
                {providers.length > maxProviders && (
                  <>
                    {' '}
                    <strong>Note</strong> this will only show the first{' '}
                    {maxProviders} surgeons.
                  </>
                )}
              </p>
              <div
                ref={doctorListRef}
                className="mb-[0px] mt-[32px] border-t-2 border-b-2 border-ossio-green py-4 w-full text-center text-black"
              >
                {info}
              </div>
              <div className="flex justify-center">
                <button
                  className="o-button  green mt-5 hidden hamburger:!block"
                  onClick={toggleMaps}
                >
                  <>
                    <span
                      className={`${
                        hamburgerHidden ? 'expand-icon' : 'collapse-icon'
                      } inline-block mr-5`}
                    ></span>
                    {hamburgerHidden && <>Expand</>}{' '}
                    {!hamburgerHidden && <>Collapse</>} Surgeon List
                    {loadingDoctorList && (
                      <Spinner className="inline-block ml-5" />
                    )}
                    <span></span>
                  </>
                </button>
              </div>
              <div
                className={`${
                  hamburgerHidden ? 'hamburger:hidden' : ''
                } overflow-y-scroll scrollbar-main pb-[18px] scrollbar-track-black scrollbar-thumb-ossio-green py-[35px] px-[25px] max-h-[70vh]`}
              >
                {providers.map((p) => {
                  const id = Object.prototype.hasOwnProperty.call(p, 'id')
                    ? p.id
                    : p.ID;

                  return (
                    <React.Fragment key={id}>
                      <SingleResult
                        scrollRef={scrollRef}
                        overrideId={id}
                        provider={p}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
            </>
          )}
        </div>
        <div
          id="sw-map-container"
          className={`lg:basis-[45%] ${
            hamburgerHidden ? 'mt-5' : 'mt-10'
          } lg:mt-0`}
        >
          {loading && <Skeleton height={500} />}
          {!loading &&
            (error ||
              Object.keys(memoizedCenterLatLng).length === 0 ||
              !Array.isArray(providers) ||
              providers.length === 0) && (
              <div className="p-5 h-full w-full relative flex items-center justify-center border-2 border-ossio-green rounded-xl">
                <div className="absolute top-0 left-0 h-full w-full bg-ossio-green opacity-[30%]"></div>
                <div className="text-black font-[900] px-[30%] md-text text-center z-[5]">
                  <>
                    <>
                      {searchHasBeenMadeForThisType && (
                        <p className="mb-5 underline">No Results Found</p>
                      )}
                      {whichTab === 'name' ? (
                        <>
                          Enter a{' '}
                          {searchHasBeenMadeForThisType && <>Different</>} Name
                          to Search for a Surgeon that Matches
                        </>
                      ) : (
                        <>
                          {whichTab === 'distance' && (
                            <>
                              Enter a{' '}
                              {searchHasBeenMadeForThisType && <>Different</>}{' '}
                              Location to Search for a Surgeon Near You
                            </>
                          )}
                        </>
                      )}
                    </>
                  </>
                </div>
              </div>
            )}
          {!error && Object.keys(memoizedCenterLatLng).length > 0 && (
            <Map
              markers={markers}
              mapStyles={{
                height: '100vh',
                width: '100%',
                marginBottom: '2vh',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Finder;

// emailModalSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
};

const emailModalSlice = createSlice({
  name: 'emailModal',
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

const { open, close } = emailModalSlice.actions;

export { open, close };

export default emailModalSlice.reducer;

// providersSlice.js

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../utilities/axios';
import remapError, { INVALID_ADDRESS } from '../../utilities/remapError';
import normalizeId from '../../utilities/normalizeId';
import validZipCode from '../../utilities/validZipCode';
import { doctorFinderSearchedEvent } from '../../utilities/gtag';

const initialState = {
  data: [],
  loading: false,
  error: null,
  firstSearchOccurred: false,
  distance: 10,
  activeProvider: null,
  centerLatLng: {},
  searchHasBeenMadeForThisType: false,
};

const filterParams = (params) => {
  const paramsCopy = { ...params };
  // Create a RegExp object
  const regex = /^\d{5}(?:[-\s]\d{4})$/;
  if (
    paramsCopy?.location &&
    regex.test(paramsCopy.location) // Use the RegExp object's test method with the string
  ) {
    paramsCopy.location = paramsCopy.location.split('-')[0];
  }
  return paramsCopy;
};

const hasError = (params) => {
  let error = null;
  // if (params.which === 'distance' && !validZipCode(params?.location)) {
  //   error = NO_ZIP_CODE;
  // }

  return error;
};

const fetchProviders = createAsyncThunk(
  'providers/fetchProviders',
  async ({ ...params }) => {
    const location = params?.location;
    const doctor_name = params?.providerName;
    const endpoint = '/provider';
    if (!Object.prototype.hasOwnProperty.call(params, 'which')) {
      return null;
    }

    let decoratedParams = filterParams(params);

    const error = hasError(decoratedParams);
    if (error) {
      throw new Error(error);
    }

    const url = `${endpoint}?${new URLSearchParams(decoratedParams)}`;
    let response;
    try {
      response = await axios.get(url);
    } catch (e) {
      throw new Error(e);
    }
    if (400 === response?.data?.data?.response?.status) {
      const errorResponse =
        response?.data?.data?.response?.data?.data?.response?.error ||
        INVALID_ADDRESS;
      throw new Error(errorResponse);
    }

    const data = {
      us_state: response?.data?.data?.response?.state || 'undefined',
      gps_coordinates: response?.data?.data?.response?.gps || 'undefined',
      doctor_search_query: location || 'undefined',
      doctor_name: doctor_name || 'undefined',
      zip_code_first_result:
        response?.data?.data?.response?.zip_code_first_result || 'undefined',
      session_id: JSON.parse(localStorage.getItem('sessionData')).sessionId,
      ga_session_id: JSON.parse(localStorage.getItem('sessionData')).sessionId,
      user_id: JSON.parse(localStorage.getItem('sessionData')).userId,
    };

    doctorFinderSearchedEvent(data);

    return response;
  },
);

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    resetSearchHasBeenMadeForThisType: (state, action) => {
      state.searchHasBeenMadeForThisType = false;
    },
    resetProviders: (state, action) => {
      state.data = [];
    },
    reset: (state, action) => {
      Object.keys(initialState).forEach((i) => {
        state[i] = initialState[i];
      });
    },
    setCenterLatLng: (state, action) => {
      state.centerLatLng = action.payload;
    },
    setActiveProvider: (state, action) => {
      const id = action.payload;

      const currentState = current(state);
      const { data } = currentState;
      const { providers } = data;
      const providerFiltered = providers.filter(
        (p) => parseInt(normalizeId(p), 10) === parseInt(id, 10),
      );
      const activeP =
        providerFiltered.length > 0 ? providerFiltered.pop() : null;
      state.activeProvider = activeP;

      if (activeP) {
        const latLng = {
          lat: parseFloat(activeP.location.lat),
          lng: parseFloat(activeP.location.lng),
        };
        state.centerLatLng = latLng;
      }
    },
    sortByYear: (state, action) => {
      const sortOrder = action.payload === 'asc' ? 1 : -1;
      state.data.sort((a, b) => sortOrder * (a.year - b.year));
    },
    sortByLength: (state, action) => {
      const sortOrder = action.payload === 'asc' ? 1 : -1;
      state.data.sort((a, b) => sortOrder * (a.length - b.length));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.activeProvider = null;
      })

      .addCase(fetchProviders.rejected, (state, action) => {
        state.loading = false;
        let error = action?.payload?.error?.message;
        if (!error) {
          error = action?.error?.message;
        }

        error = remapError(error);

        state.error = error;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        // console.log(action, 1);
        state.loading = false;
        state.error = null;
        state.searchHasBeenMadeForThisType = true;
        const providers = action?.payload?.data?.data?.response?.providers;

        (state.data = {
          providersFiltered: Array.isArray(providers)
            ? providers.filter((p) => p?.location)
            : [],
          providers,
          info: action?.payload?.data?.data?.response?.searchDescription,
        }),
          (state.firstSearchOccurred = true);
      });
  },
});

const {
  setCenterLatLng,
  sortByYear,
  sortByLength,
  setActiveProvider,
  reset,
  resetProviders,
  resetSearchHasBeenMadeForThisType,
} = providersSlice.actions;

export {
  fetchProviders,
  setCenterLatLng,
  sortByYear,
  sortByLength,
  setActiveProvider,
  reset,
  resetProviders,
  resetSearchHasBeenMadeForThisType,
};

export default providersSlice.reducer;

import generateUUID from './guid';
import { isTimeStale, oneDayFromTodayUnixTimestamp } from './time';

const doctorFinderSearchedEvent = async (data) => {
  const guidKey = window.swfinder.guidKey;
  let guid = localStorage.getItem(guidKey);
  if (guid) {
    guid = JSON.parse(guid); // Ensure proper parsing
    if (isTimeStale(guid?.time)) {
      guid = null;
    }
  }
  if (!guid) {
    guid = generateUUID();
    const newData = {
      time: oneDayFromTodayUnixTimestamp(),
      guid,
    };
    localStorage.setItem(guidKey, JSON.stringify(newData));
  }

  const event = new CustomEvent('swOssio', {
    detail: {
      ...data,
      eventName: 'doctor_finder_search',
      guid: guid?.guid || guid,
      currentTimestamp: Math.floor(Date.now() / 1000),
    },
  });
  window.dispatchEvent(event);

  gtag('event', 'doctor_finder_search', data);
};

export { doctorFinderSearchedEvent };

import { useState } from "react";

const useSelect = ({ options, defaultValue, blankLabel }) => {
  const [selected, setSelected] = useState(defaultValue);
  let actualOptions = options;

  if (blankLabel) {
    actualOptions = [].concat({ value: "", label: blankLabel }).concat(options);
  }

  const set = (v) => {
    setSelected(v);
  };

  return { set, selected, options: actualOptions };
};

export default useSelect;

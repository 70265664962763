import SWIcon from './SWIcon';
import requestAppointmentLinkBuilder from '../utilities/requestAppointmentLinkBuilder';
import { linkLikehoverHandlingClasses } from '../utilities/classes';

const ActiveProvider = (props) => {
  const {
    practice_name,
    first_name,
    last_name,
    suffix,
    type_icon,
    type_iconW,
    type_iconH,
    ossio_type,
    permalink,
    location: {
      street_address = '',
      street_address_2 = '',
      city = '',
      state = '',
      zip = '',
      practice_name: inner_practice_name,
      phone,
    },
  } = props;
  const contactUrl = requestAppointmentLinkBuilder(permalink);

  const actual_practice_name = inner_practice_name
    ? inner_practice_name
    : practice_name;

  return (
    <div style={{ fontSize: '18px' }}>
      {actual_practice_name && (
        <p>
          <strong>{actual_practice_name}</strong>
        </p>
      )}
      {(first_name || last_name) && (
        <p className="mt-3">
          {first_name} {last_name}
        </p>
      )}
      {street_address && <p>{street_address}</p>}
      {street_address_2 && <p>{street_address_2}</p>}
      {city && (
        <p>
          {city}
          {city && <>,</>} {state} {zip}
        </p>
      )}
      <div className="flex gap-5 justify-between w-full hamburger:flex-col hamburger:items-between">
        <p className="mt-3 font-bold ">
          <a className={linkLikehoverHandlingClasses} href={permalink}>
            Surgeon Information
          </a>
        </p>
        <p className="mt-3 font-bold ">
          <a className={linkLikehoverHandlingClasses} href={contactUrl}>
            Request Appointment
          </a>
        </p>
        <div>
          <SWIcon
            icon={type_icon}
            height={24}
            alt={ossio_type.name}
            className={'max-h-[50px] !w-auto'}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveProvider;

import { configureStore } from '@reduxjs/toolkit';
import providersReducer, {
  fetchProviders,
} from './features/providers/providersSlice';
import emailModalReducer from './features/providers/emailModalSlice';
import zoomReducer from './features/providers/zoomSlice';
const store = configureStore({
  reducer: {
    providers: providersReducer,
    emailModal: emailModalReducer,
    zoom: zoomReducer,
    // map: mapReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['providers/fetchProviders/fulfilled'],
      },
    }),
});

export default store;

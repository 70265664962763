import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useJsApiLoader,
  MarkerClustererF,
} from '@react-google-maps/api';

import './Map.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveProvider } from '../features/providers/providersSlice';
import ActiveProvider from './ActiveProvider';
import normalizeId from '../utilities/normalizeId';
import scrollToMap from '../utilities/scrollToMap';

const places = ['places'];
const Map = ({ markers, mapStyles }) => {
  const providers = useSelector(
    (state) => state.providers.data.providersFiltered,
  );
  const [bounds, setBounds] = useState([]);
  const [innerMap, setInnerMap] = useState(null);

  const zoomLevel = useSelector((state) => state.zoom?.zoom);
  const activeProvider = useSelector(
    (state) => state.providers?.activeProvider,
  );
  const centerLatLng = useSelector(
    (state) => state.providers?.centerLatLng || { lat: 0, lng: 0 },
  );
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToMap();
  }, [activeProvider]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: window.swfinder.apiKey,
  });

  const infoWindow = activeProvider ? (
    <InfoWindowF
      options={{ pixelOffset: new window.google.maps.Size(0, -25) }}
      position={{
        ...centerLatLng,
      }}
      onCloseClick={() => {
        dispatch(setActiveProvider(null));
      }}
      children={<ActiveProvider {...activeProvider} />}
    ></InfoWindowF>
  ) : (
    <></>
  );

  useEffect(() => {
    if (markers.length > 0 && isLoaded) {
      const bounds = new window.google.maps.LatLngBounds();

      let lat, lng;
      let padMarkers = true;
      markers.forEach((marker) => {
        if (
          padMarkers &&
          lat &&
          lng &&
          (parseFloat(lat) !== parseFloat(marker.lat) ||
            parseFloat(lng) !== parseFloat(marker.lng))
        ) {
          padMarkers = false;
        }
        bounds.extend({
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        });
        lat = marker.lat;
        lng = marker.lng;
      });

      if (markers.length === 1 || padMarkers) {
        // Assuming each marker object has lat and lng as properties
        const { lat, lng } = markers[0];
        const latitude = parseFloat(lat);
        const longitude = parseFloat(lng);

        // Rough approximation: 1 degree of latitude = ~69 miles
        // For 3 miles, it's about 3/69 degrees. Longitude varies, but we'll use the same approximation for simplicity.
        const deltaLat = 3 / 69;
        const deltaLng = 3 / 69;

        // Extend bounds in all four directions
        bounds.extend(
          new window.google.maps.LatLng(
            latitude + deltaLat,
            longitude + deltaLng,
          ),
        );
        bounds.extend(
          new window.google.maps.LatLng(
            latitude - deltaLat,
            longitude - deltaLng,
          ),
        );
        bounds.extend(
          new window.google.maps.LatLng(
            latitude + deltaLat,
            longitude - deltaLng,
          ),
        );
        bounds.extend(
          new window.google.maps.LatLng(
            latitude - deltaLat,
            longitude + deltaLng,
          ),
        );
      }

      setBounds(bounds);
      if (innerMap) {
        innerMap.fitBounds(bounds);
      }
    }
  }, [markers, isLoaded]);

  return (
    <>
      {isLoaded &&
        providers &&
        providers.length > 0 &&
        Object.keys(bounds).length > 0 && (
          <div className="flex">
            <div className="basis" id="g-map-wrapper">
              <GoogleMap
                bounds={bounds}
                onLoad={(map) => {
                  setInnerMap(map);
                  map.fitBounds(bounds);
                }}
                mapContainerStyle={mapStyles}
                zoom={zoomLevel}
                center={centerLatLng}
                options={{
                  disableDefaultUI: true,
                  zoomControl: true,
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_RIGHT,
                  },
                }}
              >
                {infoWindow}
                <MarkerClustererF>
                  {(clusterer) => (
                    <>
                      {markers.map((data, index) => {
                        const { lat, lng, provider } = data;
                        const { dropper_icon, dropperW, dropperH } = provider;
                        const id = normalizeId(provider);
                        let position = {
                          lat: parseFloat(lat),
                          lng: parseFloat(lng),
                        };

                        return (
                          <MarkerF
                            onClick={() => {
                              dispatch(setActiveProvider(id));
                            }}
                            key={index}
                            position={position}
                            icon={{
                              url: dropper_icon,
                              scaledSize: { width: dropperW, height: dropperH },
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                </MarkerClustererF>
              </GoogleMap>
            </div>
          </div>
        )}
    </>
  );
};

Map.defaultProps = {
  markers: [],
  zoom: 4,
};

export default Map;

export const NO_ZIP_CODE = 'NO_ZIP_CODE';
export const INVALID_ZIP_CODE = 'INVALID_ZIP_CODE';
export const INVALID_ADDRESS = 'INVALID_ADDRESS';
export const ZERO_RESULTS = 'ZERO_RESULTS';

const remapError = (error) => {
  switch (error) {
    case NO_ZIP_CODE:
      return `Please enter a 5 digit zip code.`;
    case INVALID_ADDRESS:
      return `Please enter a valid address.`;
    case INVALID_ZIP_CODE:
      return `Please enter a valid zip code.`;
    case ZERO_RESULTS:
      return (
        <p className="text-center">
          Address not recognized. <br />
          <strong>Hint:</strong> try entering in a zip code (e.g., 10001) or
          city and state (e.g., New York, NY).
        </p>
      );
    default:
      return `Error, you may have entered an invalid address.`;
  }
};

export default remapError;

// zoomSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  zoom: 10,
};

const zoomSlice = createSlice({
  name: 'zoom',
  initialState,
  reducers: {
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

const { setZoom } = zoomSlice.actions;

export { setZoom };

export default zoomSlice.reducer;

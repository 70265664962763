import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

const generateSessionId = () => uuidv4().replace(/-/g, '').slice(0, 16);
const generateUserId = () => uuidv4().replace(/-/g, '').slice(0, 12);

const useUserSession = () => {
  const [sessionData, setSessionData] = useState({
    userId: null,
    sessionId: null,
    expirationDate: null,
  });

  useEffect(() => {
    // Load data from local storage
    const storedSessionData = JSON.parse(localStorage.getItem('sessionData'));

    let currentUserId = storedSessionData?.userId || generateUserId();
    let currentSessionId = storedSessionData?.sessionId;
    let currentExpirationDate = storedSessionData?.expirationDate ? new Date(storedSessionData.expirationDate) : null;

    const now = new Date();

    // If no sessionId exists or the session has expired, generate a new one
    if (!currentSessionId || !currentExpirationDate || now > currentExpirationDate) {
      currentSessionId = generateSessionId();
      currentExpirationDate = new Date(now.getTime() + SESSION_DURATION);
    }

    const newSessionData = {
      userId: currentUserId,
      sessionId: currentSessionId,
      expirationDate: currentExpirationDate,
    };

    // Store the new session data in local storage
    localStorage.setItem('sessionData', JSON.stringify(newSessionData));

    // Update state
    setSessionData(newSessionData);
  }, []);

  return sessionData;
};

export default useUserSession;

import axios from 'axios';

const instance = axios.create({
  baseURL: window.swfinder.restBase,
  headers: {
    common: {
      'X-WP-Nonce': window.swfinder.nonce,
    },
  },
  validateStatus: function (status) {
    return status == 200;
  },
});

export default instance;

function normalizeId(object) {
  if (!object || typeof object !== 'object') {
    return false;
  }
  let actualId = false;
  ['id', 'ID', 'post_id', 'postid'].forEach((key) => {
    if (!actualId && Object.prototype.hasOwnProperty.call(object, key)) {
      actualId = object[key];
    }
  });
  return actualId;
}

export default normalizeId;

function generateMailto(data, address, distance, maxProviders = 30) {
  let body = `The results of your OSSIOfiber® search for surgeons within ${distance} miles of ${address} are as follows:\n\n`;

  for (let i = 0; i < maxProviders; i++) {
    const provider = data[i];
    const location = provider.location;

    body += `${i + 1}. ${provider.first_name} ${provider.last_name}\n`;
    body += `${location.street_address}\n`;
    if (location.street_address_2) {
      body += `${location.street_address_2}\n`;
    }
    body += `${location.city}, ${location.state} ${location.zip}\n`;
    body += `Phone: ${location.phone}\n`;
    body += `Website: ${location.website}\n\n`;
  }

  const subject = "OSSIOfiber® Surgeon Locator Results";
  const encodedSubject = encodeURIComponent(subject);
  const encodedBody = encodeURIComponent(body);

  const mailtoURL = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;

  window.open(mailtoURL, "_blank");
}

export default generateMailto;

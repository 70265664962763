import { useDispatch, useSelector } from 'react-redux';
import { setActiveProvider } from '../features/providers/providersSlice';
import SWIcon from './SWIcon';
import requestAppointmentLinkBuilder from '../utilities/requestAppointmentLinkBuilder';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import normalizeId from '../utilities/normalizeId';
import { setZoom } from '../features/providers/zoomSlice';
import Tooltip from 'react-simple-tooltip';

const SingleResult = (p) => {
  const { provider, overrideId, className = '' } = p;
  const activeProvider = useSelector((state) => {
    return state.providers.activeProvider;
  });

  const {
    id,
    distance,
    first_name,
    last_name,
    suffix,
    practice_name,
    location,
    headshot,
    permalink,
    type_icon,
    ossio_type,
    specialties,
  } = provider;

  const actualId = id ? id : overrideId;
  const {
    city = '',
    state = '',
    street_address = '',
    street_address_2 = '',
    zip = '',
    practice_name: inner_practice_name,
  } = location;

  const actual_practice_name = inner_practice_name
    ? inner_practice_name
    : practice_name;

  const linkClass = `block  text-[16px] underline hover:no-underline mb-5 transition-all duration-300 ${
    actualId === normalizeId(activeProvider)
      ? 'text-white hover:text-black'
      : 'text-green hover:text-ossio-green hover:opacity-[80%] '
  } text-center`;
  const dispatch = useDispatch();
  const contactUrl = requestAppointmentLinkBuilder(permalink);
  return (
    <div
      onClick={() => {
        dispatch(setActiveProvider(actualId));
        if (isNaN(distance)) {
          dispatch(setZoom(13));
        }
      }}
      className={`${className} ${
        actualId === normalizeId(activeProvider)
          ? 'bg-ossio-green text-white provider-active'
          : 'bg-gray text-dark provider-inactive'
      } w-full px-[12px] cursor-pointer py-[24px] rounded ossio-shadow hover:ossio-shadow-hover text-center  flex flex-col mb-5 `}
    >
      <div className="flex justify-start items-stretch lg:px-5 flex-wrap lg:flex-nowrap">
        <div className="shrink-0 mt-[8px] basis-full lg:basis-[21%] mobile-only:flex mobile-only:justify-center hamburger:max-h-[200px]">
          <LazyLoadImage
            className="max-w-[150px]"
            src={headshot}
            alt={`${first_name} ${last_name}${suffix}`}
          />
        </div>
        <div className="flex lg:w-full pl-3 lg:pl-0 basis-full lg:basis-auto pr-3">
          <div className="flex flex-col text-left flex-grow-[1] mobile-only:items-center mobile-only:my-5">
            <div className="flex items-center justify-center lg:justify-start">
              <h1
                className={`${
                  actualId === normalizeId(activeProvider)
                    ? 'text-white'
                    : 'text-ossio-green'
                } underline md-text text-[22px] text-center lg:text-left`}
              >
                {first_name} {last_name}
                {suffix}
              </h1>
              <SWIcon
                icon={type_icon}
                height={24}
                width={'auto'}
                alt={ossio_type?.name}
                className="ml-3 lg:hidden max-h-[50px] !w-auto"
              />
            </div>
            <p
              className={`sm-text ${
                actualId === normalizeId(activeProvider)
                  ? 'text-white'
                  : 'text-ossio-green'
              }`}
            >
              {!isNaN(distance) && <>{parseFloat(distance).toFixed(2)} miles</>}
            </p>
            {actual_practice_name && (
              <p className="text-center lg:text-left">
                <strong>{actual_practice_name}</strong>
              </p>
            )}

            <div className="mt-2 hamburger:text-center">
              {street_address && <p className="my-0">{street_address}</p>}
              {street_address_2 && <p className="my-0">{street_address_2}</p>}
              <p className="my-0">
                {city}, {state}, {zip}
              </p>
            </div>
          </div>
        </div>
        <div
          className={`lg:border-l-2 shrink-0 lg:basis-[36%] justify-between lg:pl-[16px] flex flex-col mobile-only:w-full mobile-only:items-center mobile-only:justify-center mobile-only:text-center ${
            normalizeId(activeProvider) ? 'border-white' : 'border-ossio-green'
          }`}
        >
          <div className="h-full items-center my-4 lg:my-0 hidden lg:flex">
            {ossio_type.name}
          </div>
          <a href={permalink} className={linkClass}>
            Provider Information
          </a>
          <a href={contactUrl} className={linkClass}>
            Request Appointment
          </a>
        </div>
      </div>

      {specialties && Array.isArray(specialties) && specialties.length > 0 && (
        <div className="py-5 flex gap-5 hide-empty-container">
          {specialties.map(({ icon, icon_inverted, name }, index) => {
            return !icon && !icon_inverted ? (
              <React.Fragment key={index}></React.Fragment>
            ) : (
              <Tooltip
                content={name.replace(/&amp;/g, '&')}
                customCss={{
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                }}
                padding={12}
                border="#61bb46"
                background="#FFFFFF"
                color="#61bb46"
                fadeDuration={120}
                radius={60}
                key={name}
              >
                <img
                  src={icon || icon_inverted}
                  alt={name}
                  class="provider-active-icon"
                />
                <img
                  src={icon_inverted || icon}
                  alt={name}
                  class="provider-inactive-icon"
                />
              </Tooltip>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SingleResult;
